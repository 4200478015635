import { useEffect, useState } from "react";
import { Button, Col, Layout, Row, Space, Spin, Table, message } from "antd";
import columnDevice from "./table/data.table.inactive";
import requets from "../../api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { ReportCsv } from "../../api/service/report/reportCsv";
import { ReportExcel } from "../../api/service/report/reportExcel";
import { useSelector } from "react-redux";
import moment from "moment/moment";
const { Content } = Layout;

export default function DeviceInactive() {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const { t } = useTranslation("global");
  const [messageSend, contextHolder] = message.useMessage();
  const controlPanel = useSelector((state) => state.controlPanel);
  const navigate = useNavigate();
  const [data, setDataSource] = useState([]);
  const [spinning, setSpinning] = useState(false);

  const showLoader = () => {
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 10000);
  };

  const getDevices = (control) => {
    const { department, county, zone, devEui } = control;
    req
      .get("/device/filter/", { department, county, zone, devEui })
      .then((response) => {
        let devicesInactive = response.data;
        devicesInactive = devicesInactive.filter(
          (device) => device.isActive === false
        );
        setDataSource(devicesInactive);
      });
  };

  const controlDevice = (devEuiParam, action) => {
    req
      .post(`/device/control/${devEuiParam}`, { action: action })
      .then((response) => {
        if (response.status !== 200) {
          messageSend.open({
            type: "error",
            content: t("deviceEdit.error"),
          });
        }
      });
  };

  const updateDevice = (devEuiParam, value) => {
    req.put(`/device/${devEuiParam}`, value).then((response) => {
      if (response.status === 200) {
        messageSend.open({
          type: "success",
          content: t("deviceEdit.sucessUpdated"),
        });
        getDevices(controlPanel);
      }
    });
  };

  const onChange = (checked, device) => {
    if (device.mode === "MANUAL") {
      const state = checked ? "ON" : "OFF";
      updateDevice(device.devEui, { state });
      const action = checked ? "R-ON" : "R-OFF";
      controlDevice(device.devEui, action);
      showLoader();
    } else {
      messageSend.open({
        type: "error",
        content: t("deviceEdit.errorUpdated"),
      });
    }
  };

  useEffect(() => {
    getDevices(controlPanel);
  }, [controlPanel]);

  const translate = (measurement) => {
    const reports = measurement.map((item) => {
      let response = {
        nameLamp: item.name,
        description: item.description,
        eui: item.devEui,
        join: item.joinEui,
        appkey: item.appKey,
        zone: item.zone.label,
        marquille: item.marquille,
        brand: item.brand,
        state:
          item.state === 0
            ? t("reports.tableColumn.stateValueOff")
            : t("reports.tableColumn.stateValueOn"),
        status:
          item.status === "Fail"
            ? t("reports.tableColumn.statusValueBad")
            : t("reports.tableColumn.statusValueGood"),
        consecutive: item.consecutive,
        manufacturingDate: item.manufacturingDate,
        installationDate: item.installationDate,
      };
      const objet = {};

      for (const key in response) {
        const keyTranslate = t(`device.tableColumn.${key}`);
        objet[`${keyTranslate}`] = response[key];
      }

      return objet;
    });

    return reports;
  };

  return (
    <>
      <Spin spinning={spinning} fullscreen="true" tip={t("lamp.spin")}>
        <Content>
          <Row justify="end">
            <Col>
              <Space wrap="true">
                <ReportExcel
                  dataSet={translate(data)}
                  fileName={
                    t("device.devicesReport") +
                    "-" +
                    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                  }
                />
                <ReportCsv
                  dataSet={translate(data)}
                  fileName={
                    t("device.devicesReport") +
                    "-" +
                    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                  }
                />
              </Space>
            </Col>
          </Row>
          {contextHolder}
          <Table
            columns={columnDevice(onChange)}
            dataSource={data}
            scroll={{ x: "calc(700px + 50%)" }}
            rowKey="devEui"
          ></Table>
        </Content>
      </Spin>
    </>
  );
}

/* eslint-disable react/prop-types */
/**
 * Hooks React
 */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/**
 * Time
 */
import moment from "moment-timezone";
/**
 * Link para las Rutas
 */
import { Link } from "react-router-dom";
/**
 * iconos
 */
import {
  FaPowerOff,
  FaUser,
  FaClock,
  FaAngleDown,
  FaBars,
} from "react-icons/fa";
import { IoLanguageOutline } from "react-icons/io5";
import { UserOutlined, NotificationOutlined } from "@ant-design/icons";
/**Componentes Antd */
import {
  Avatar,
  Button,
  Col,
  Row,
  Space,
  Switch,
  Dropdown,
  Breadcrumb,
  Badge,
} from "antd";
import { useKeycloak } from "@react-keycloak/web";
import requets from "../../api";
import { icons } from "../icons/icon";
/**
 * Otros Componentes
 */

/**
 *
 * @param {*} props
 * @returns
 */

export default function Header(props) {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);

  const [notification, setNotification] = useState([]);
  /**
   * Control de los valores del Reloj
   */
  const timezone = moment.tz.guess();
  const [dataTime, setDataTime] = useState(
    `${moment().tz(timezone).format("LL")} ${moment()
      .tz(timezone)
      .format("LTS")}`
  );
  // inicializacion del hook para las  traducciones
  const { t, i18n } = useTranslation("global");

  const getNotifications = () => {
    req.get("/push-notification/").then((response) => {
      if (response.data) {
        const data = response.data.map((item) => {
          return {
            key: item.uuid,
            label: (
              <div
                style={{
                  width: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar size="small" icon={icons[item.icon]} />
                  <div style={{ marginLeft: 10 }}>
                    <div>
                      <strong>{item.type}</strong>
                    </div>
                    <div>{item.description}</div>
                  </div>
                </div>
                <Button
                  type="text"
                  onClick={() => handleDeleteEvent(item.uuid)}
                  danger
                >
                  Eliminar
                </Button>
              </div>
            ),
          };
        });
        setNotification(data);
      }
    });
  };
  const handleDeleteEvent = (key) => {
    req.delete(`/push-notification/${key}`).then((response) => {
      if (response.status === 200) {
        getNotifications();
      }
    });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  /**
   * Menu Desplegable
   */
  const items = [
    {
      key: "0",
      label: (
        <Link to="/profile">
          {" "}
          <Button type="primary" className="nav-link" icon={<FaUser />} block>
            {t("header.profile")}
          </Button>{" "}
        </Link>
      ),
    },
    {
      label: (
        <Button
          type="primary"
          onClick={() => keycloak.logout(window.location.origin)}
          icon={<FaPowerOff />}
          block
        >
          <span>{t("header.logout")}</span>
        </Button>
      ),
      key: "3",
    },
  ];
  /**
   * control del Scroll
   */
  useEffect(() => window.scrollTo(0, 0), []);
  /**
   * Reloj
   */
  useEffect(() => {
    setInterval(() => {
      setDataTime(
        `${moment().tz(timezone).format("LL")} ${moment()
          .tz(timezone)
          .format("LTS")}`
      );
    }, 1000);
  }, []);

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb
            separator="/"
            items={[{ title: <Link to="/">home</Link> }]}
          />
          <div className="ant-page-header-heading">
            <span className="ant-page-header-heading-title"></span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Space size={[8, 16]} wrap>
            <Dropdown
              placement="bottom"
              menu={{
                items: notification,
              }}
            >
              <Badge count={notification.length}>
                <Avatar size="small" icon={<UserOutlined />} />
              </Badge>
            </Dropdown>

            <Button
              type="link"
              className="sidebar-toggler"
              onClick={() => props.onPress()}
            >
              {<FaBars />}
            </Button>
            <Button shape="round" icon={<FaClock />}>
              <span align="center">{dataTime}</span>
            </Button>

            <Avatar size={40} style={{ background: "#0000fd" }}>
              {keycloak.tokenParsed.given_name.length > 4
                ? keycloak.tokenParsed.given_name.slice(0, 4)
                : keycloak.tokenParsed.given_name}
            </Avatar>
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              arrow={{ pointAtCenter: true }}
            >
              <Space>
                <FaAngleDown />
              </Space>
            </Dropdown>
            <Switch
              onClick={(props) => {
                i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
              }}
              checkedChildren={<IoLanguageOutline />}
              unCheckedChildren={<IoLanguageOutline />}
            />
          </Space>
        </Col>
      </Row>
    </>
  );
}

import { useTranslation } from "react-i18next";
import { Button, Radio, Space, Switch, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import SearchDataIndex from "../../../context/share/SearchDataIndex";
import { useNavigate } from "react-router-dom";

export default function Column(
  handleDeleteEvent,
  onChange,
  handlerDimerization,
  handlerMessage
) {
  const getDataIndex = SearchDataIndex();
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  const column = [
    {
      title: t("device.tableColumn.status"),
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Button
          type="text"
          onClick={() => navigate("/lamp/", { state: { device: record } })}
        >
          {t("device.open")}
        </Button>
      ),
    },
    {
      title: t("device.tableColumn.nameLamp"),
      dataIndex: "name",
      key: "name",
      ...getDataIndex("name"),
    },
    {
      title: t("device.tableColumn.marquille"),
      dataIndex: "marquille",
      key: "marquille",
      ...getDataIndex("marquille"),
    },
    { title: t("device.tableColumn.brand"), dataIndex: "brand", key: "brand" },
    {
      title: t("device.tableColumn.eui"),
      dataIndex: "devEui",
      key: "deveui",
      ...getDataIndex("devEui"),
    },
    {
      title: t("device.tableColumn.option"),
      dataIndex: "devEui",
      key: "option",
      render: (_, record) => (
        <Space direction="vertical">
          <Space>
            <Button
              type="text"
              icon={<EditOutlined />}
              href={`/device/${record.devEui}/edit`}
            >
              {t("device.edit")}
            </Button>
          </Space>
        </Space>
      ),
    },
  ];

  return column;
}

/**
 * Definición de URL Servidores
 */
const host = "https://iot-backend-ilesa.idtolu.net"; //production
//const host = "https://bck-ilesa.idtolu.net"; //Cluster Development
//const host ="http:localhost:3139"
//const host = 'http://10.0.102.9:3139'; //Remote Server Developmet

/**
 *
 * @returns path Server Local
 */
export function server() {
  const server = host;
  return server;
}

import { useKeycloak } from "@react-keycloak/web";
import {
  Space,
  Layout,
  Card,
  Input,
  Divider,
  Avatar,
  Form,
  List,
  DatePicker,
  Typography,
  message,
  Table,
} from "antd";
import {
  FaRegFolder,
  FaRegFileExcel,
  FaRegTrashAlt,
  FaCloudDownloadAlt,
  FaArrowLeft,
} from "react-icons/fa";
import requets from "../../api";
import { useEffect, useState } from "react";
import { server } from "../../api/service/server/axios";
export default function FilesReports() {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const [messageSend, contextHolder] = message.useMessage();
  const { Content } = Layout;
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [path, setPath] = useState("");
  const { Search } = Input;

  const fetchFolders = () => {
    req.get("/report-file/folder/").then((response) => {
      setFolders(response.data);
      setFiles([]);
    });
  };

  const fetchByFolders = (folder) => {
    req.get("/report-file/byfolder/", { folder }).then((response) => {
      setFiles(response.data);
    });
  };

  const deleteFile = (id, folder) => {
    req.delete(`/report-file/${id}`).then((response) => {
      if (response.status === 200) {
        messageSend.open({
          type: "success",
          content: "Archivo eliminado",
          duration: 1600,
        });

        fetchByFolders(folder);
      }
    });
  };
  const handleDownload = async (pathFile, fileName) => {
    try {
      const response = await fetch(pathFile);
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        pathFile.replace("https://buckets.idtolu.net/ilesa/", "")
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      messageSend.open({
        type: "Error",
        content: "Error al descargar archivo",
        duration: 1600,
      });
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  const columns = [
    {
      title: "NOMBRE ARCHIVO",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "DIRECTORIO",
      dataIndex: "pathFile",
      key: "pathFile",
    },
    {
      title: "FECHA CREACION",
      dataIndex: "createAt",
      key: "createAt",
    },
    {
      title: "FECHA EXPIRACION",
      dataIndex: "expirationDate",
      key: "expirationDate",
    },
    {
      title: "USUARIO CREADOR",
      dataIndex: "createBy",
      key: "createBy",
      render: (_, { createBy }) => <span>{createBy}</span>,
    },
    {
      title: "OPCION",
      key: "",
      render: (_, { uuid, fileName, pathFile, folder }) => (
        <Space>
          <a onClick={() => handleDownload(pathFile)} download={`${fileName}`}>
            {contextHolder}
            <FaCloudDownloadAlt size={20} className="item-pointer" />
          </a>

          <FaRegTrashAlt
            size={20}
            className="item-pointer"
            onClick={() => deleteFile(uuid, folder)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card
        extra={
          <div>
            <Form layout="inline">
              <Form.Item label="Buscar por nombre">
                <Input
                  placeholder="Ingresa el nombre"
                  style={{
                    width: "200px",
                  }}
                />
              </Form.Item>
              <Form.Item label="Buscar por fecha">
                <DatePicker
                  placeholder="Ingrese la fecha"
                  size="large"
                  style={{
                    width: "200px",
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        }
      >
        <Space align="center">
          {!!files.length && (
            <FaArrowLeft
              className="item-pointer"
              onClick={() => fetchFolders()}
              size={20}
            />
          )}

          <p style={{ marginTop: "5px" }}>Ruta: </p>
          <FaRegFolder size={24} />
          <p></p>
        </Space>
        {!files.length && (
          <List
            dataSource={folders}
            renderItem={(item, index) => (
              <List.Item
                key={index}
                onClick={() => fetchByFolders(item.folder)}
              >
                <List.Item.Meta
                  avatar={
                    <FaRegFolder style={{ marginTop: "2px" }} size={24} />
                  }
                  description={
                    <div className="center-item">
                      <p className="file-text tem-text">
                        <strong>{item.folder}</strong>
                      </p>
                    </div>
                  }
                />
              </List.Item>
            )}
          ></List>
        )}

        {!!files.length && (
          <Table
            scroll={{ x: "calc(700px + 50%)" }}
            columns={columns}
            dataSource={files}
          />
        )}
      </Card>
    </Content>
  );
}


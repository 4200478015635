import { useTranslation } from "react-i18next";
import { Button, Radio, Space, Switch, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import SearchDataIndex from "../../../context/share/SearchDataIndex";
import { useNavigate } from "react-router-dom";
import { render } from "@testing-library/react";
import {
  numberToDays,
  numberToMonths,
  actionsListToName,
} from "../form-value.js";
import moment from "moment-timezone";
export default function Column(handleDeleteEvent, onChange) {
  const getDataIndex = SearchDataIndex();
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  const column = [
    {
      title: t("automation.tableColumn.name"),
      dataIndex: "name",
      key: "name",
      ...getDataIndex("name"),
    },
    {
      title: t("automation.tableColumn.devicesList"),
      dataIndex: "devicesList",
      key: "devicesList",
      render: (_, record) => {
        return record.devicesList.map((item) => (
          <Tag color="blue" key={item}>
            {item.name}
          </Tag>
        ));
      },
    },
    {
      title: t("automation.tableColumn.actionList"),
      dataIndex: "actionList",
      key: "actionList",
      render: (_, record) => {
        return record.actionList.map((item) => (
          <Tag color="blue" key={item}>
            {t(`automation.actions.${actionsListToName()[item]}`)}
          </Tag>
        ));
      },
    },
    {
      title: t("automation.tableColumn.daysOfWeek"),
      dataIndex: "daysOfWeek",
      key: "daysOfWeek",
      render: (_, record) => {
        return record.daysOfWeek.map((item) => (
          <Tag color="blue" key={item}>
            {t(`automation.days.${numberToDays()[item]}`)}
          </Tag>
        ));
      },
    },
    {
      title: t("automation.tableColumn.months"),
      dataIndex: "months",
      key: "months",
      render: (_, record) => {
        return record.months.map((item) => (
          <Tag color="blue" key={item}>
            {t(`automation.months.${numberToMonths()[item]}`)}
          </Tag>
        ));
      },
    },
    {
      title: t("automation.tableColumn.state"),
      dataIndex: "isActive",
      key: "isActive",
      render: (_, record) => {
        return record.isActive === true ? (
          <Tag color="green">Activo</Tag>
        ) : (
          <Tag color="red">Inactivo</Tag>
        );
      },
    },
    {
      title: t("automation.tableColumn.createBy"),
      dataIndex: "createBy",
      key: "createBy",
      ...getDataIndex("createBy"),
    },
    {
      title: t("automation.tableColumn.updateBy"),
      dataIndex: "updateBy",
      key: "updateBy",
      ...getDataIndex("updateBy"),
    },
    {
      title: t("automation.tableColumn.createDate"),
      dataIndex: "createAt",
      key: "createAt",
      render: (_, record) => {
        return moment(record.createAt)
          .tz("America/Bogota")
          .format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: t("automation.tableColumn.actions"),
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<EditOutlined size={"large"} />}
            onClick={() => navigate(`/scheduled/${record.uuid}/edit`)}
          >
            {t("automation.tableColumn.edit")}
          </Button>
          <Button
            type="text"
            danger
            icon={<DeleteOutlined size={"large"} />}
            onClick={() => handleDeleteEvent(record.uuid)}
          >
            {t("automation.tableColumn.delete")}
          </Button>
        </Space>
      ),
    },
  ];

  return column;
}

export const valueOptionsMinutes = () => {
  const minutesOptions = [];
  for (let i = 0; i < 60; i++) {
    minutesOptions.push({
      label: i,
      value: i,
    });
  }
  return minutesOptions;
};

export const valueOptionsHours = () => {
  const hoursOptions = [];
  for (let i = 0; i < 24; i++) {
    hoursOptions.push({
      label: i,
      value: i,
    });
  }
  return hoursOptions;
};
export const valueOptionsDaysOfMonth = () => {
  const daysOptions = [];
  for (let i = 1; i < 32; i++) {
    daysOptions.push({
      label: i,
      value: i,
    });
  }
  return daysOptions;
};

export const valueOptionsDaysOfWeek = () => {
  const daysOptions = [
    { label: "Lunes", value: "1" },
    { label: "Martes", value: "2" },
    { label: "Miércoles", value: "3" },
    { label: "Jueves", value: "4" },
    { label: "Viernes", value: "5" },
    { label: "Sábado", value: "6" },
    { label: "Domingo", value: "7" },
  ];
  return daysOptions;
};
export const numberToDays = () => {
  const daysOptions = {
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
    7: "sunday",
  };

  return daysOptions;
};

export const valueOptionsMonths = () => {
  const monthsOptions = [
    { label: "Enero", value: "1" },
    { label: "Febrero", value: "2" },
    { label: "Marzo", value: "3" },
    { label: "Abril", value: "4" },
    { label: "Mayo", value: "5" },
    { label: "Junio", value: "6" },
    { label: "Julio", value: "7" },
    { label: "Agosto", value: "8" },
    { label: "Septiembre", value: "9" },
    { label: "Octubre", value: "10" },
    { label: "Noviembre", value: "11" },
    { label: "Diciembre", value: "12" },
  ];
  return monthsOptions;
};

export const numberToMonths = () => {
  const monthsOptions = {
    1: "january",
    2: "february",
    3: "march",
    4: "april",
    5: "may",
    6: "june",
    7: "july",
    8: "august",
    9: "september",
    10: "october",
    11: "november",
    12: "december",
  };
  return monthsOptions;
};

export const actionList = () => {
  const actions = [
    { label: "MANUAL", value: "MANUAL" },
    { label: "ON", value: "R-ON" },
    { label: "OFF", value: "R-OFF" },
    { label: "Dimerizacion - 100%", value: "ALTO" },
    { label: "Dimerizacion - 75%", value: "INTERM" },
    { label: "Dimerizacion - 50%", value: "MEDIO" },
    { label: "Dimerizacion - 10%", value: "BAJA" },
    { label: "Envios - 1M", value: "T=1M" },
    { label: "Envios - 3M", value: "T=3M" },
    { label: "Envios - 5", value: "T=5M" },
    { label: "Envios - 15", value: "T=15" },
    { label: "AUTO", value: "AUTO" },
  ];
  return actions;
};
export const actionsListToName = () => {
  const actions = {
    "R-ON": "on",
    "R-OFF": "off",
    ALTO: "high",
    INTERM: "middle",
    MEDIO: "half",
    BAJA: "low",
    "T=1M": "tm1",
    "T=3M": "tm3",
    "T=5M": "tm5",
    "T=15": "tm15",
    AUTO: "automatic",
    MANUAL: "manual",
  };

  return actions;
};

import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Layout,
  Row,
  Select,
  Tabs,
  message,
  Button,
  Form,
  Input,
  Switch,
} from "antd";
import requets from "../../api";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";
import {
  valueOptionsMinutes,
  valueOptionsHours,
  valueOptionsDaysOfMonth,
  valueOptionsDaysOfWeek,
  valueOptionsMonths,
  actionList,
} from "./form-value.js";
import { useParams } from "react-router-dom";
const { Content } = Layout;
const { Option } = Select;
export default function ScheduledEdit() {
  const { keycloak } = useKeycloak();
  const { uuid } = useParams();
  const req = requets(keycloak.token);
  const controlPanel = useSelector((state) => state.controlPanel);
  const { t } = useTranslation("global");
  const [messageSend, contextHolder] = message.useMessage();
  const [scheduled, setScheduled] = useState({});
  const [devices, setDevices] = useState([]);
  const [multiCastingGroup, setMultiCastingGroup] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (values.type === "single") {
      let devicesList = values.devicesList
        .map((item) => {
          let device = devices.find((dev) => dev.devEui === item);
          if (device) return { devEui: device.devEui, name: device.name };
          return null;
        })
        .filter(Boolean);
      values.devicesList = devicesList;
    }

    if (values.type === "multicasting") {
      let devicesList = values.devicesList
        .map((item) => {
          let multicastGroup = multiCastingGroup.find((dev) => dev.id === item);
          if (multicastGroup)
            return { id: multicastGroup.id, name: multicastGroup.name };
          return null;
        })
        .filter(Boolean);
      values.devicesList = devicesList;
    }

    req.put(`/scheduled-control/${uuid}`, values).then((response) => {
      if (response.status === 200) {
        messageSend.open({
          type: "success",
          content: t("automationEdit.successUpdated"),
        });
      } else {
        messageSend.open({
          type: "error",
          content: t("automationEdit.errorUpdated"),
        });
      }
    });
  };

  const selectTypeOptions = [
    { value: "single", label: "Single" },
    { value: "multicasting", label: "Multicasting" },
  ];
  const selectOptionsMap = {
    single: devices.map((device) => ({
      value: device.devEui,
      label: device.name,
    })),
    multicasting: multiCastingGroup.map((group) => ({
      value: group.id,
      label: group.name,
    })),
  };

  const handleFirstOptionChange = (value) => {
    setSelectedOption(value);
  };
  const getMultiCastingGroup = () => {
    req.get("/multicasting/").then((response) => {
      let data = response.data;
      setMultiCastingGroup(data);
    });
  };

  const FormEdit = () => {
    return (
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row wrap="true" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} span={24}>
            <Form.Item
              label={t("automationNew.name")}
              name="name"
              initialValue={scheduled.name}
              messageVariables={{ nameField: t("automationNew.name") }}
              rules={[
                { required: true, message: t("formValidator.mandatory") },
              ]}
            >
              <Input placeholder={t("automationNew.placeHolderName")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              label={t("automationNew.hour")}
              name="hours"
              initialValue={scheduled.hours}
              messageVariables={{
                nameField: t("automationNew.hour"),
              }}
              rules={[
                { required: true, message: t("formValidator.mandatory") },
              ]}
            >
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={t("automationNew.placeHolderHour")}
                initialValue={scheduled.hours}
                options={valueOptionsHours()}
                rules={[
                  { required: true, message: t("formValidator.mandatory") },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              initialValue={scheduled.minutes}
              label={t("automationNew.minutes")}
              name="minutes"
              messageVariables={{
                nameField: t("automationNew.minutes"),
              }}
              rules={[
                { required: true, message: t("formValidator.mandatory") },
              ]}
            >
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={t("automationNew.placeHolderMinutes")}
                options={valueOptionsMinutes()}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              initialValue={scheduled.daysOfWeek}
              label={t("automationNew.daysOfWeek")}
              name="daysOfWeek"
              messageVariables={{
                nameField: t("automationNew.daysOfWeek"),
              }}
              rules={[
                { required: true, message: t("formValidator.mandatory") },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={t("automationNew.placeHolderDaysOfWeek")}
                options={valueOptionsDaysOfWeek()}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              initialValue={scheduled.daysOfMonths}
              label={t("automationNew.daysOfMonth")}
              name="daysOfMonths"
              messageVariables={{
                nameField: t("automationNew.daysOfMonth"),
              }}
              rules={[
                { required: true, message: t("formValidator.mandatory") },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={t("automationNew.placeHolderDaysOfMonth")}
                options={valueOptionsDaysOfMonth()}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              initialValue={scheduled.months}
              label={t("automationNew.months")}
              name="months"
              messageVariables={{
                nameField: t("automationNew.months"),
              }}
              rules={[
                { required: true, message: t("formValidator.mandatory") },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={t("automationNew.placeHolderMonths")}
                options={valueOptionsMonths()}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              initialValue={scheduled.actionList}
              label={t("automationNew.actionList")}
              name="actionList"
              messageVariables={{
                nameField: t("automationNew.actionList"),
              }}
              rules={[
                { required: true, message: t("formValidator.mandatory") },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={t("automationNew.placeHolderActionList")}
                options={actionList()}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              label="Type"
              initialValue={scheduled.type}
              name="type"
              messageVariables={{
                nameField: "Type",
              }}
              rules={[
                { required: true, message: t("formValidator.mandatory") },
              ]}
            >
              <Select
                value={selectedOption}
                onChange={handleFirstOptionChange}
                placeholder="Type"
              >
                {selectTypeOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {selectedOption && (
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label="Select Option"
                initialValue={scheduled.devicesList}
                name="devicesList"
                messageVariables={{
                  nameField: "Type",
                }}
                rules={[
                  { required: true, message: t("formValidator.mandatory") },
                ]}
              >
                <Select placeholder="Select an option" mode="multiple">
                  {selectOptionsMap[selectedOption].map((option) => (
                    <Option key={option.key} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              label={t("automationEdit.state")}
              name="isActive"
              initialValue={scheduled.isActive}
              valuePropName="checked"
              messageVariables={{ nameField: t("automationEdit.state") }}
              rules={[
                { required: true, message: t("formValidator.mandatory") },
              ]}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          {contextHolder}
          <Button
            className="mb-2"
            shape="round"
            htmlType="submit"
            type="primary"
          >
            {t("deviceEdit.saveChange")}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const tabs = [
    {
      key: "1",
      label: "Editar Automatizacion",
      children: <FormEdit />,
    },
  ];

  useEffect(() => {
    const { department, county, zone, devEui } = controlPanel;
    req
      .get("/device/filter/", { department, county, zone, devEui })
      .then((response) => {
        let devicesActive = response.data;
        devicesActive = devicesActive.filter(
          (device) => device.isActive === true
        );
        setDevices(devicesActive);
      });
    req.get(`/scheduled-control/${uuid}`).then((response) => {
      const rta = response.data;
      rta.devicesList = rta.devicesList.map((device) => {
        return device.devEui;
      });
      setScheduled(rta);
      getMultiCastingGroup();
    });
  }, [uuid]);
  return (
    <>
      <Content>
        <Card bordered={false}>
          <Tabs
            defaultActiveKey="1"
            activeKey={activeTab}
            onTabClick={handleTabChange}
            items={tabs}
          />
        </Card>
      </Content>
    </>
  );
}

import { Route, Routes } from "react-router-dom";
import { Main } from "../components/layouts/Main.js";
import Home from "../view/home.jsx";
import Device from "../view/device/device.jsx";
import Report from "../view/report/report.jsx";
import UnAthorized from "../view/status/unathorized.jsx";
import SinglePage from "../view/public/singlePage.jsx";
import { Layout, Spin } from "antd";
import { useKeycloak } from "@react-keycloak/web";
import DeviceEdit from "../view/device/device.edit.jsx";
import DeviceNew from "../view/device/device.new.jsx";
import DeviceInactive from "../view/device/device.inactive.jsx";
import Zone from "../view/zone/zone.jsx";
import Alert from "../view/alert/alert.jsx";
import Notification from "../view/notification/notification.jsx";
import Lamp from "../view/lamp/lamp.jsx";
import Register from "../view/public/register.jsx";
import User from "../view/users/user.jsx";
import Config from "../view/config/config.jsx";
import Profile from "../view/users/profile/profile.jsx";
import FilesReports from "../view/files/files-report.jsx";
import Scheduled from "../view/automation/scheduled.new.jsx";
import ScheduledEdit from "../view/automation/scheduled.edit.jsx";
const Content = Layout;
const contentStyle = {
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  minHeight: "800px",
  alignItems: "center",
  lineHeight: "120px",
  color: "#fff",
};
export default function AuthRoutes() {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <Layout>
        <Content style={contentStyle}>
          <Spin tip="Loading" size="large" />
        </Content>
      </Layout>
    );
  }

  if (keycloak.hasResourceRole("super-admin")) {
    return (
      <Main>
        <Routes>
          <Route path="/report" element={<Report />} />
          <Route path="/files-reports" element={<FilesReports />} />
          <Route path="/device" element={<Device />} />
          <Route path="/device-inactive" element={<DeviceInactive />} />
          <Route path="/device/new" element={<DeviceNew />} />
          <Route path="/device/:devEui/edit" element={<DeviceEdit />} />
          <Route path="/scheduled" element={<Scheduled />} />
          <Route path="/scheduled/:uuid/edit" element={<ScheduledEdit />} />
          <Route path="/zones" element={<Zone />} />
          <Route path="/alert" element={<Alert />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/config-alert" element={<Config />} />
          <Route path="/lamp/" element={<Lamp />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Main>
    );
  }
  /**
   * Rutas Admin
   */
  if (keycloak.hasResourceRole("admin")) {
    return (
      <Main>
        <Routes>
          <Route path="/report" element={<Report />} />
          <Route path="/files-reports" element={<FilesReports />} />
          <Route path="/device" element={<Device />} />
          <Route path="/device-inactive" element={<DeviceInactive />} />
          <Route path="/device/new" element={<DeviceNew />} />
          <Route path="/device/:devEui/edit" element={<DeviceEdit />} />
          <Route path="/scheduled" element={<Scheduled />} />
          <Route path="/scheduled/:uuid/edit" element={<ScheduledEdit />} />
          <Route path="/zones" element={<Zone />} />
          <Route path="/alert" element={<Alert />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/config" element={<Config />} />
          <Route path="/lamp/" element={<Lamp />} />
          <Route path="/users" element={<User />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Main>
    );
  }

  if (keycloak.hasResourceRole("demo")) {
    return (
      <Main>
        <Routes>
          <Route path="/report" element={<Report />} />
          <Route path="/files-reports" element={<FilesReports />} />
          <Route path="/device" element={<Device />} />
          <Route path="/device-inactive" element={<DeviceInactive />} />
          <Route path="/device/new" element={<DeviceNew />} />
          <Route path="/device/:devEui/edit" element={<DeviceEdit />} />
          <Route path="/device/:devEui/edit" element={<DeviceEdit />} />
          <Route path="/scheduled" element={<Scheduled />} />
          <Route path="/scheduled/:uuid/edit" element={<ScheduledEdit />} />
          <Route path="/zones" element={<Zone />} />
          <Route path="/alert" element={<Alert />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/config" element={<Config />} />
          <Route path="/lamp/" element={<Lamp />} />
          <Route path="/users" element={<User />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Main>
    );
  }


  /**
   * Rutas Viewer
   */
  if (keycloak.hasResourceRole("viewer")) {
    return (
      <Main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/alert" element={<Alert />} />
          <Route path="/report" element={<Report />} />
          <Route path="/files-reports" element={<FilesReports />} />
          <Route path="/lamp/" element={<Lamp />} />
        </Routes>
      </Main>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<SinglePage />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<UnAthorized />} />
    </Routes>
  );
}

import { useEffect, useState } from "react";
import {
  Col,
  Layout,
  Pagination,
  Row,
  Space,
  Table,
  Spin,
  Button,
  message,
} from "antd";
import columnReport from "./table/data.table";
import requets from "../../api";
import { useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import ReportExcel from "../../api/service/report/reportExcel";
import { ReportCsv } from "../../api/service/report/reportCsv";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { FaFileExcel } from "react-icons/fa";

const { Content } = Layout;

export default function Report() {
  const controlPanel = useSelector((state) => state.controlPanel);
  const { keycloak } = useKeycloak();
  const { t } = useTranslation("global");
  const req = requets(keycloak.token);
  const [data, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState(null);
  const [count, setCount] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const [messageSend, contextHolder] = message.useMessage();

  useEffect(() => {
    setSpinning(true);

    let { date, department, county, zone, devEui } = controlPanel;

    if (date === undefined) {
      date = {
        start: moment().subtract(1, "day").valueOf(),
        end: moment().valueOf(),
      };
    }

    req
      .get("/measurement/count/", {
        ...date,
        department,
        county,
        zone,
        devEui,
        pageSize: 10,
        pageState: JSON.stringify(pageState),
      })
      .then((response) => {
        setCount(response.data[0].counttotal);
      });

    req
      .get("/measurement/filter/", {
        ...date,
        department,
        county,
        zone,
        devEui,
        pageSize,
        pageState: JSON.stringify(pageState),
      })
      .then((response) => {
        setDataSource(response.data.rows);
        setPageState(response.data.pageState);
      })
      .finally(() => setSpinning(false));
  }, [controlPanel, currentPage, pageSize]);

  const changePages = (current, pageSizePaginate) => {
    if (isNaN(pageSizePaginate)) {
      pageSizePaginate = count;
    }
    setCurrentPage(current);
    setPageSize(pageSizePaginate);
  };

  const onShowSizeChange = (current, pageSizePaginate) => {
    if (isNaN(pageSizePaginate)) {
      pageSizePaginate = count;
    }

    setCurrentPage(current);
    setPageSize(pageSizePaginate);
  };
  const handleDownloadExcel = (extensionFile) => {
    let { date, department, county, zone, devEui } = controlPanel;
    if (date === undefined) {
      date = {
        start: moment().subtract(1, "day").valueOf(),
        end: moment().valueOf(),
      };
    }
    req
      .get("/measurement/report/excel/", {
        ...date,
        department,
        county,
        zone,
        devEui,
        pageSize,
        pageState: JSON.stringify(pageState),
        extensionFile,
      })
      .then((response) => {
        messageSend.open({
          type: "success",
          content: `${response.data.message}`,
          duration: 1600,
        });
      });
  };

  const totalPage = () => {
    return `Total ${count}`;
  };

  const translate = (measurement) => {
    let valueDimerizacion = {
      223: "100%",
      112: "75%",
      56: "50%",
      30: "10%",
    };
    const reports = measurement.map((item) => {
      let response = {
        date: moment(parseInt(item.date)).format("YYYY-MM-DD HH:mm:ss"),
        nameLamp: item.name,
        dev_eui: item.dev_eui,
        current: item.current,
        dimerization: valueDimerizacion[item.dimerization],
        energy: item.energy,
        energy_accumulated: item.energy_accumulated,
        voltage: item.voltage,
        frequency: item.frequency,
        latitude: item.latitude,
        longitude: item.longitude,
        power: item.power,
        power_factor: item.power_factor,
        state:
          item.state === 0
            ? t("reports.tableColumn.stateValueOff")
            : t("reports.tableColumn.stateValueOn"),
        status:
          item.status === "Fail"
            ? t("reports.tableColumn.statusValueBad")
            : t("reports.tableColumn.statusValueGood"),
      };
      const objet = {};

      for (const key in response) {
        const keyTranslate = t(`reports.tableColumn.${key}`);
        objet[`${keyTranslate}`] = response[key];
      }

      return objet;
    });

    return reports;
  };

  return (
    <Content>
      {contextHolder}
      <Spin spinning={spinning} fullscreen="true" tip={t("loading")}>
        <Row justify="end">
          <Col>
            <Space>
              <Button
                type="primary"
                shape="round"
                onClick={(e) => handleDownloadExcel("xlsx")}
                icon={<FaFileExcel />}
                style={{
                  backgroundColor: "#389e0d",
                }}
              >
                {t("reports.excel")}
              </Button>
              {/* <ReportExcel
                dataSet={translate(data)}
                fileName={
                  t("reports.measurement") +
                  "-" +
                  moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                }
                onClick={handleDownloadExcel}
              /> 
              <ReportCsv
                dataSet={translate(data)}
                fileName={
                  t("reports.measurement") +
                  "-" +
                  moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                }
              />*/}
              <Button
                type="primary"
                shape="round"
                onClick={(e) => handleDownloadExcel("csv")}
                icon={<FaFileExcel />}
                style={{
                  backgroundColor: "#389e0d",
                }}
              >
                {t("reports.csv")}
              </Button>
            </Space>
          </Col>
        </Row>
        <Table
          columns={columnReport()}
          dataSource={data}
          scroll={{ x: "calc(700px + 50%)" }}
          rowKey="date"
          pagination={false}
        ></Table>
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          pageSize={pageSize}
          total={count}
          showTotal={totalPage}
          onChange={changePages}
          showSizeChanger
          pageSizeOptions={["10", "20", "50", "100", "200", "500", "1000"]}
          onShowSizeChange={onShowSizeChange}
        />
      </Spin>
    </Content>
  );
}

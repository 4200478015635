import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ILESA from "../../assets/images/Ilesa2.svg";
import EMTIOT from "../../assets/images/emtiots.svg";
import IDTOLU from "../../assets/images/idtolu.svg";
import {
  FaRegChartBar,
  FaMap,
  FaTh,
  FaRegBell,
  FaClipboardList,
  FaCalendarAlt 
} from "react-icons/fa";
import { AiOutlineFundProjectionScreen, AiOutlineUsergroupAdd, AiOutlineSetting, AiFillSound,AiFillEyeInvisible   } from "react-icons/ai";
import { useKeycloak } from "@react-keycloak/web";
import { icon } from "leaflet";

function getItem(key, label) {
  return {
    key,
    label,
  };
}
/**
 *
 * @param {Configuracion con el proveedor de identidad} props
 * @returns
 */
export default function SideNav(props) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  // inicializacion del hook para las  traducciones
  const { t } = useTranslation("global");
  const { keycloak } = useKeycloak();

  const roleConfig = {
    "super-admin": [
      { path: "/", icon: <FaTh />, label: t("sidenav.dashboard") },
      { path: "device", icon: <AiOutlineFundProjectionScreen />, label: t("sidenav.device") },
      { path: "device-inactive", icon: <AiFillEyeInvisible />, label: t("sidenav.device-inactive") },
      {path: "scheduled", icon: <FaCalendarAlt />, label: t("sidenav.automation")},
      { path: "zones", icon: <FaMap />, label: t("sidenav.zones") },
      { path: "report", icon: <FaRegChartBar />, label: t("sidenav.report") },
      { path: "files-reports", icon: <FaClipboardList />, label: t("sidenav.list-report") },
      { path: "alert", icon: <AiFillSound />  , label: t("sidenav.alert") },
      { path: "users", icon: <AiOutlineUsergroupAdd />, label: t("sidenav.user") },
      { path: "config", icon: <AiOutlineSetting />, label: t("sidenav.configAlert") },
      { path: "notification", icon: <FaRegBell />, label: t("sidenav.notification") },
    ],
    "admin": [
      { path: "/", icon: <FaTh />, label: t("sidenav.dashboard") },
      { path: "device", icon: <AiOutlineFundProjectionScreen />, label: t("sidenav.device") },
      { path: "device-inactive", icon: <AiFillEyeInvisible />, label: t("sidenav.device-inactive") },
      {path: "scheduled", icon: <FaCalendarAlt />, label: t("sidenav.automation")},
      { path: "zones", icon: <FaMap />, label: t("sidenav.zones") },
      { path: "report", icon: <FaRegChartBar />, label: t("sidenav.report") },
      { path: "files-reports", icon: <FaClipboardList />, label: t("sidenav.list-report") },
      { path: "alert", icon: <AiFillSound />, label: t("sidenav.alert") },
      { path: "users", icon: <AiOutlineUsergroupAdd />, label: t("sidenav.user") },
      { path: "config", icon: <AiOutlineSetting />, label: t("sidenav.configAlert") },
      { path: "notification", icon: <FaRegBell />, label: t("sidenav.notification") },
    ],
    "viewer": [
      { path: "/", icon: <FaTh />, label: t("sidenav.dashboard") },
      { path: "report", icon: <FaRegChartBar />, label: t("sidenav.report") },
      { path: "files-reports", icon: <FaClipboardList />, label: t("sidenav.list-report") },
      { path: "alert", icon: <AiFillSound />, label: t("sidenav.alert") },
      
    ],
    "demo": [
      { path: "/", icon: <FaTh />, label: t("sidenav.dashboard") },
      { path: "device", icon: <AiOutlineFundProjectionScreen />, label: t("sidenav.device") },
      {path: "scheduled", icon: <FaCalendarAlt />, label: t("sidenav.automation")},
      { path: "zones", icon: <FaMap />, label: t("sidenav.zones") },
      { path: "report", icon: <FaRegChartBar />, label: t("sidenav.report") },
      { path: "files-reports", icon: <FaClipboardList />, label: t("sidenav.list-report") },
      { path: "alert", icon: <AiFillSound />, label: t("sidenav.alert") },
      { path: "users", icon: <AiOutlineUsergroupAdd />, label: t("sidenav.user") },
      { path: "config", icon: <AiOutlineSetting />, label: t("sidenav.configAlert") },
      { path: "notification", icon: <FaRegBell />, label: t("sidenav.notification") },
    ]
  }

  const logo = {
    "super-admin": IDTOLU,
    "admin": ILESA,
    "viewer": ILESA,
    "demo": EMTIOT
  }

  const rol = Object.keys(roleConfig).find(role => keycloak.hasResourceRole(role));
  const items = roleConfig[rol].map((item, index) => {
    return getItem(index, <Link to={item.path} className="nav-link">
      <span
        className="icon"
        style={{
          background: page === item.path ? props.color : "",
        }}
      >
        {item.icon}
      </span>{" "}
      <span className="label">{item.label}</span>
    </Link>);
  });

  return (
    <>
      <div className="brand">
        <img src={logo[rol]} width="170px" height="250px" alt="" />
      </div>
      <div>
        <Menu mode="inline" theme="light" items={items} />
      </div>
    </>
  );
}
